<template>
    <div class="client-box">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>库存管理</el-breadcrumb-item>
            <el-breadcrumb-item>出库单</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button @click="add" type="primary" size="small" icon="el-icon-plus">添加</el-button>
                </el-form-item>
                <el-form-item v-if="addRule">
                    <el-button plain @click="download" type="success" size="small" icon="el-icon-download">导出</el-button>
                </el-form-item>
                <el-form-item label="状态背景">
                    <el-checkbox v-model="showBg"></el-checkbox>
                </el-form-item>
                <el-form-item label="物品">
                     <el-input v-model="form.key"  placeholder="关键字搜索"></el-input>
                </el-form-item>
                <el-form-item >
                    <el-select v-model="form.wid" clearable placeholder="选择仓库">
                        <el-option
                        v-for="item in warehouseList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                    v-model="form.timeArr"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="pro-con">
            <el-table
            border
            v-loading="loading"
            :data="list"
            :row-class-name="tableRowClassName"
            style="width: 100%">
                <!-- 展开行 -->
                <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <div class="form-item"  v-for='(item,index) in props.row.list' v-bind:key="index">
                            <el-form-item label="物品名称">
                            <span>{{ item.name}}</span>
                            </el-form-item>
                            <el-form-item label="出库量">
                                <span>{{ item.number }} {{ item.unit }}</span>
                            </el-form-item>
                            <el-form-item label="价格">
                                <span>{{ item.money }} 元</span>
                            </el-form-item>
                        </div>
                        
                    </el-form>
                </template>
                </el-table-column>
                <!-- 展开行-end -->
                <el-table-column
                    prop="date"
                    label="日期">
                </el-table-column>
                <el-table-column
                    prop="en_id"
                    label="出库编号">
                </el-table-column>
                <el-table-column
                    prop="wname"
                    label="仓库名称">
                </el-table-column>
                <el-table-column
                    prop="related_party"
                    label="申请领用人">
                </el-table-column>
                <el-table-column
                    prop="originator"
                    label="填表人">
                </el-table-column>
                <el-table-column
                    prop="checker"
                    label="审核人">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态">
                    <template slot-scope="scope">
                        <el-tag :type="Number(scope.row.status) === 2?'success':Number(scope.row.status) === 1?'warning':Number(scope.row.status) === 3?'danger':'info'">
                          {{scope.row.status|EnxStatus(scope.row.status)}}
                        </el-tag>  
                    </template>
                </el-table-column>
                <el-table-column
                    prop="total"
                    label="合计 （元）">
                </el-table-column>
                <el-table-column
                width="100"
                fixed="right"
                v-if="editRule"
                label="操作">
                    <template slot-scope="scope">
                        <i title="查看详情" class="edit el-icon-view" @click="editItem(scope.row.en_id)"></i>
                        <i title="下载" class="edit el-icon-download" @click="downloadSign(scope.row)"></i>
                        <i title="删除" class="edit el-icon-delete-solid" @click="handleDelete(scope.row.en_id)"></i>
                        <i title="签名" v-if="scope.row.status==1" @click="signItem(scope.row)" class="edit el-icon-edit-outline" >审核签名</i>
                        <i title="签名" v-if="scope.row.status==0" @click="signItem(scope.row)" class="edit el-icon-edit-outline" >制单签名</i>
                    </template>
                </el-table-column>
                
            </el-table>
        </div>
        <!-- 添加物品 -->
        <el-dialog :title="edit_id==''?'添加出库单':'查看出库单'" 
        :before-close="closeAdEn"
        width="86%"
        :visible.sync="drawer">
            <AddEx ref="addEnRef" @addSuc="addSuc" :en_id="edit_id"/>
        </el-dialog>
        <!-- Sign签名 -->
        <el-dialog :title="signTitle" :visible.sync="signDialogShow" v-loading="loading">
            <Sign @commit="commitSign"/>
        </el-dialog>
    </div>
</template>
<script>
import {formatTime,checkRules} from '@/utils/util.js'
import {exportExcel} from '@/utils/excel.js'
import AddEx from '@/components/AddEx'
import Sign from '@/components/Sign'
export default {
    components:{
        AddEx,
        Sign
    },
    data(){
        return{
            signData:{},//签名信息
            signTitle:'签名',//签名名称
            signDialogShow:false,//显示签名
            warehouseList:[],//仓库列表
            showBg:false,//显示状态背景色
            edit_id:'',
            loading:false,
            drawer:false,
            addRule:checkRules(13),//添加权限
            editRule:checkRules(13),//编辑权限
            page:1,
            pageSize:10,
            totalPage:0,
            form:{
                key:"",
                wid:"",
                timeArr:[new Date(new Date().getTime()-24*60*60*1000),new Date(new Date().getTime()+24*60*60*1000)]
            },
            list:[]
        }
    },
    created(){
        this.getEntering()
        //获取仓库列表
        this.searchWarehouse()
    },
    methods:{
        //下载
        downloadSign(e){
            //console.log(e)
            let params = e 
            params.dataList = JSON.stringify(e.list)
            params.method = "download_enx"
            params.type = "deliver"
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false 
                //console.log(data)
                if(data.file){
                    //下载文件
                    let fileUrl = this.API.api+'download/'+data.file
                    //console.log(fileUrl)
                    window.open(fileUrl)
                }
            }).catch(err=>{
                //console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //提交签名
        commitSign(e){
            //console.log(e)
            let params = {
                method:"upload_base64",
                next_method:"signature",
                base64:e,
                formData:JSON.stringify(this.signData)
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false 
                //console.log(data)
                if(data){
                    this.signature_url = this.CONST.api + data
                    this.signature()
                }
            }).catch(err=>{
                console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //上传签名图片及状态
        signature(){
            let params = this.signData
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false 
                this.signDialogShow = false
                console.log(data)
                this.search()
            }).catch(err=>{
                console.log(err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //签名$firmid,$wid,$type,$sign_type,$url,$username,$status
        signItem(e){
            this.signData = {
                method:"signature",
                enid:e.en_id,
                id:e.id,
                sign_type:e.status,
                type:"deliver",
                status:parseInt(e.status)+1
            }
            // console.log(this.signData)
            this.signDialogShow = true
        },
        //导出数据
        download(){
            //console.log(this.list)
            let columnTitle=['出库日期','出库编号','仓库名称','来往单位','金额(元)','状态']
            let columnData = []
            let startAr = formatTime(this.form.timeArr[0]).split(" "),endArr = formatTime(this.form.timeArr[1]).split(" ")
            this.list.forEach(item=>{
                columnData.push([item.date,item.en_id,item.wname,item.related_party,item.total,this.getStatus(Number(item.status))])
            })
            
            exportExcel(columnTitle, columnData, "入库单_"+startAr[0]+"_"+endArr[0]);
        },
        //获取数据状态
        getStatus(status){
            let str = '待审核'
            switch(status){
                case 1:
                    str = '待签名'
                    break;
                case 2:
                    str = '已完成'
                    break;
                case 3:
                    str = '已拒绝'
                    break;
            }
            return str
        },
        //搜索仓库
        searchWarehouse(key){
            //console.log(key)
            !key?key='':''
            let params = {
                method:"getWarehouse",
                key,
                page:1,
                pageSize:5
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false 
                //console.log(data)
                if(data){
                    data.list.forEach(item=>{
                        item.label = item.name 
                        item.value = item.num
                    })
                    this.warehouseList = data.list
                }
            }).catch(err=>{
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //表格状态
        tableRowClassName({row}) {
            let className = ''
            if(!this.showBg){
                return ''
            }
            switch (row.status *1){
                case 0:
                    break;
                case 1:
                    className = 'warning-row'
                    break;
                case 2:
                    className = 'success-row'
                    break;
                case 3:
                    className = 'refuse-row'
                    break;
            }
            return className
        },
        //添加成功
        addSuc(){
            this.drawer = false 
            this.getEntering()
        },
        //添加入库
        add(){
            this.drawer = true
            this.edit_id =''
        },
        search(){
            this.getEntering()
        },
        //编辑
        editItem(en_id){
            this.drawer = true
            this.edit_id = en_id
        },
        //获取出库
        getEntering(){
            let form = this.form
            form.method = "getEx"
            form.page = this.page 
            form.pageSize = this.pageSize
            //获取时间
            console.log(form.timeArr)
            form.start = formatTime(form.timeArr[0]).split(' ')[0]
            form.end = formatTime(form.timeArr[1]).split(' ')[0]
            console.log(form)
            this.loading = true
            this.$ajax.post(this.API.api,form).then(({data})=>{
                console.log(data)
                this.loading = false 
                if(data){
                    //遍历数据
                    data.forEach((item)=>{
                       let list=[]
                       let arr_name = item.goods_name.split(',')
                       let arr_number = item.number.split(',')
                       let arr_unit = item.unit.split(',')
                       let arr_money = item.money.split(',')
                       let arr_num = item.goods_num.split(",")
                       arr_name.forEach((it,index)=>{
                           list.push({
                               name:it,
                               num:arr_num[index]||'',
                               number:arr_number[index]||0,
                               unit:arr_unit[index]||'',
                               money:arr_money[index]||''
                           })
                       })
                       item.list = list
                    })
                    console.log(data)
                    this.list = data
                }

            }).catch(err=>{
                console.log(err.data)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        //关闭添加
        closeAdEn(cb){
            //console.log('close',cb)
            this.$refs['addEnRef'].close(cb)
            this.edit_id=''
            return false
        },
        //删除入库
        handleDelete(en_id){
           // console.log(id)
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let params = {
                        method:'delEx',
                        en_id
                    }
                    this.loading=true
                    //console.log(params)
                    this.$ajax.post(this.API.api,params).then(res=>{
                        //console.log(res)
                        //刷新
                        this.search()
                        this.$message({
                            message: res.msg||this.CONST.DEL_SUC,
                            type: 'success'
                        });
                    }).catch(err=>{
                        console.log(err)
                        this.loading = false 
                        this.$message({
                            message: err.msg||this.CONST.DEL_FAILD,
                            type: 'warning'
                        });
                    })

                })
        }
    }
}
</script>
<style lang="less">
.demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 30%;
  }
</style>